import { default as classNames, default as classnames } from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute } from 'react';
import { getFieldErrors } from '../../redux/slice/errors';
import { getFormFieldOverride } from '../../redux/slice/ui';
import { useAppSelector } from '../../redux/store';
import { FormErrors, type OverridableFormFields } from '../../typedefs';
import ElementWrapper from './ElementWrapper';

type FormValue = string | number | readonly string[];

const disableScrollWheel = (e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur();

type FormElementProps = {
  name: string;
  type: HTMLInputTypeAttribute;
  label?: string;
  suffix?: string;
  className?: string;
  value?: FormValue;
  checked?: boolean;
  step?: string | number;
  placeholder?: string;
  description?: string | React.ReactNode;
  descriptionBelowInput?: boolean;
  required?: boolean;
  compact?: boolean;
  size?: number; // for email, password, tel, url, and text
  min?: number | string; // for numbers only
  max?: number | string; // for numbers only
  accept?: string; // for file input only
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  errors?: FormErrors;
  disabled?: boolean;
  autoFocus?: boolean;
  minLength?: number;
  allowPasswordTracking?: boolean;
  compensateForUndefinedValue?: boolean;
  autoComplete?: string;
};

const FormElement: React.FC<FormElementProps> = ({
  name,
  value,
  step,
  className,
  checked,
  required,
  description: descriptionFromProps,
  descriptionBelowInput,
  label: labelFromProps,
  placeholder: placeholderFromProps,
  type,
  compact,
  size,
  min,
  max,
  accept,
  suffix,
  inputRef,
  onChange,
  onFocus,
  onBlur,
  errors,
  disabled,
  autoFocus,
  minLength,
  allowPasswordTracking,
  compensateForUndefinedValue,
  autoComplete,
}) => {
  const fieldErrors = useAppSelector((state) => errors?.fieldErrors || getFieldErrors(state));
  const specificFieldErrors = fieldErrors[name];

  const formFieldsOverride = useAppSelector((state) => getFormFieldOverride(state, name as OverridableFormFields));
  const label = formFieldsOverride?.label ?? labelFromProps;
  const description = formFieldsOverride?.description ?? descriptionFromProps;
  const placeholder = formFieldsOverride?.placeholder ?? placeholderFromProps;
  const effectiveValue = compensateForUndefinedValue ? value ?? '' : value;

  return (
    <ElementWrapper
      className={classNames('FormElement', className)}
      name={name}
      compact={compact}
      label={label}
      description={description}
      descriptionBelowInput={descriptionBelowInput}
      inputFirst={type === 'checkbox'}
      specificFieldErrors={specificFieldErrors}
    >
      <div
        className={classnames(
          {
            'is-invalid': specificFieldErrors,
            'd-flex flex-row align-items-center mt-2': type !== 'checkbox',
            'd-inline-block me-2': type === 'checkbox',
          },
          'input-container',
        )}
      >
        <input
          data-1p-ignore={!allowPasswordTracking}
          type={type}
          className={classnames({
            'is-invalid': specificFieldErrors,
            'is-disabled': disabled,
            'form-check-input': type === 'checkbox',
            'form-control': type !== 'checkbox',
          })}
          ref={inputRef}
          name={name}
          id={name}
          checked={checked}
          step={step}
          required={required}
          value={effectiveValue}
          size={size}
          min={min}
          minLength={minLength}
          max={max}
          accept={accept}
          onWheel={type === 'number' ? disableScrollWheel : undefined} // Disable the scroll wheel on number inputs
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder && `Ex: ${placeholder}`}
          autoComplete={autoComplete}
        />
        {suffix && <div className="p-2 suffix">{suffix}</div>}
      </div>
    </ElementWrapper>
  );
};

export default FormElement;
