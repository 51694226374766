import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getShowEmailInputOnIntro, getShowPhoneInputOnIntro, validateIntro } from '../../redux/selectors';
import type { RootState } from '../../redux/slice';
import { getServiceType } from '../../redux/slice/formData';
import { getStepHeaderOverride, getStepSubHeaderOverride } from '../../redux/slice/ui';
import { STEP_NAMES } from '../../utils/constants';
import { ServiceType, StepType } from '../../utils/enums';
import StepLayout from '../common/StepLayout';
import StepPanel from '../common/StepPanel';
import ContactInputs from './ContactInputs';
import IntroMobileHero from './IntroMobileHero';
import LayoutPreview from './LayoutPreview';
import LayoutSelector from './LayoutSelector';
import ProjectFilterSelector from './ProjectFilterSelector';
import ServicePreview from './ServicePreview';
import ServiceSelector from './ServiceSelector';

function mapStateToProps(store: RootState) {
  return {
    serviceType: getServiceType(store),
    showPhoneInputOnIntro: getShowPhoneInputOnIntro(store),
    showEmailInputOnIntro: getShowEmailInputOnIntro(store),
    stepHeaderOverride: getStepHeaderOverride(store, STEP_NAMES.INTRO),
    stepSubHeaderOverride: getStepSubHeaderOverride(store, STEP_NAMES.INTRO),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IntroProps = PropsFromRedux; // NOSONAR

class Intro extends React.PureComponent<IntroProps> {
  render() {
    const { stepHeaderOverride, stepSubHeaderOverride } = this.props;
    const stepHeader = stepHeaderOverride || 'Get Started'; // NOSONAR
    const stepSubHeader =
      stepSubHeaderOverride || // NOSONAR
      'Select the options that best match your needs to see tailored recommendations and estimates.';

    return (
      <StepLayout
        className="Intro"
        currentStepName={StepType.INTRO}
        mobileHero={<IntroMobileHero />}
        stepValidation={validateIntro}
      >
        <StepPanel header={stepHeader} subHeader={stepSubHeader}>
          {this.renderContents()}
        </StepPanel>
      </StepLayout>
    );
  }

  showLayoutSelector() {
    const { serviceType } = this.props;
    return serviceType !== undefined && serviceType !== ServiceType.REPAIR;
  }

  renderContents() {
    const { showPhoneInputOnIntro, showEmailInputOnIntro } = this.props;
    return (
      <>
        <ServiceSelector />
        <ServicePreview />
        {this.showLayoutSelector() && (
          <>
            <ProjectFilterSelector className="mt-5" />
            <LayoutSelector className="mt-5" />
            <LayoutPreview />
          </>
        )}
        {(showEmailInputOnIntro || showPhoneInputOnIntro) && (
          <ContactInputs showEmailInputOnIntro={showEmailInputOnIntro} showPhoneInputOnIntro={showPhoneInputOnIntro} />
        )}
      </>
    );
  }
}

export default connector(Intro);
